import { Injectable, Inject } from '@angular/core';
import { Jwt } from './Jwt';
import { cookie } from './cookie';
import { Urls } from './urls';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

import { WebSocketService } from 'src/app/services/websocket/web-socket.service';

@Injectable({
    providedIn: 'root'
})
export class environment {


    private secure = true;
    // public domain = 'face2facescheduler.com';
    public domain = 'stagetest.face2facescheduler.com';
    // public domain = 'dev.f2f.com';

    public url = 'http://panel.face2facescheduler.com';
    public secureUrl = 'https://panel.face2facescheduler.com';

    // public url = 'http://stagingapi.face2facescheduler.com';
    // public secureUrl = 'https://stagingapi.face2facescheduler.com';

    // public url = 'http://api.face2facescheduler.com';
    // public secureUrl = 'https://api.face2facescheduler.com';

    private devicetoken = 'no-device';
    private deviceid = 'no-device';
    private apikey = 'Jk7BfTC5bgGFVQXETPKjcMnrNVam56Q35cXY2yHP8GjaBJ5wbfQgjxp5SyXRhgNhbs922MZnv9qwyQ7mNuL3PWSHmzAJCcL2MENd';

    private socket_port = 3000; // live
    // private socket_port = 3001; // panel ;
    // private socket_port = 3002; // test

    public meetingCancellationSocket;

    public webSocket : WebSocketService;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private http: HttpClient,
        private cookie: cookie
    ) {


    }

    initSocket(event_id){

        let socket_option = {
            query:{
                is_admin: 1,
                event_id: event_id
            }
        }
        this.setSocket('webSocket', WebSocketService, this.socket_port, socket_option );
    }

    setSocket(socketContainer: any, socket:any, port:any, opt: any = null):any{
        let socketConfig = {
            url: 'https://face2facescheduler.com:'+port,
            options: {}
        }
        if(opt){
            socketConfig.options = opt;
        }

        this[socketContainer] = new socket(socketConfig);
    };

    getUrl( path: string ):any{
        let url = '';
        if(this.secure == true){
            url += this.secureUrl;
        } else {
            url += this.url;
        }
        url += path;
        return url;
    };

    createUrlParam(p){
        let uriStr = '?';
        for (let key of Object.keys(p)) {
          if(p[key]){
            uriStr += key + '=' + p[key] + '&';
          }
        }
        return uriStr
    }

    getToken():string{
        // this.cookie.setCookie('token', 'token 2', 2, '/', '.test.dv');
        // this.cookie.setCookie('token', null, -1, '/', '.test.dv');

        // console.log(this.cookie.getCookie('token'));
        // // console.log('asdasd');
        // let tz = Jwt.getTimezone();
        // let token = Jwt.setAlgo('HS256')
        //     .setClaim('token', 'exchange')
        //     .setClaim('tzoffset', tz.gmt)
        //     .setClaim('tzname', tz.name)
        //     .setIssuedAt()
        //     .setSecret(this.apikey)
        //     .getToken();
        // return token;
        return 'true';
    }
    setToken(token: any, day: number = 0):any{
        this.cookie.setCookie('token', token, day, '/', '.'+this.domain);
    }

    getCookie(name: string):any{
        return this.cookie.getCookie(name);
    }

    deleteCookie():any{
        return this.cookie.deleteCookie('token', '.'+this.domain);
    }

    generateToken() {
        let token = this.getCookie('token');
        if(!token.length || token == 'undefined'){
            let tz = Jwt.getTimezone();
            let token = Jwt.setAlgo('HS256')
                .setClaim('token', 'exchange')
                .setClaim('tzoffset', tz.gmt)
                .setClaim('tzname', tz.name)
                .setIssuedAt()
                .setSecret(this.apikey)
                .getToken();
            return token;
        } else {
            return token;
        }
    }

    getHttpOptions(){
        let httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer '+this.generateToken(),
                'Devicetoken': 'no-device',
                'Deviceid': 'no-device'
            })
        };
        return httpOptions;
    }

    exchangeToken() {
        let token = this.getCookie('token');
        if(!token.length || token == 'undefined'){
            let httpOptions = this.getHttpOptions();
            let url = this.getUrl(Urls.get_token) + '?device_token=' + this.devicetoken;
            return this.http.get<any>(url, httpOptions)
                .subscribe(data => {this.setToken(data.data.token)});
        }
    }

    isLogedIn(){
        let token = this.getCookie('token');
        if(token && token != 'undefined'){
            let payload = Jwt.getPayload(token);
            if(typeof payload.jti === 'undefined') {
                return false;
            }
            else {
                return true;
            }
        } else {
            return false;
        }
    }

    showLoader(){
        this.document.body.classList.add("loading");
    }

    hideLoader(){
        this.document.body.classList.remove("loading");
    }

    b64toBlob(b64Data, contentType='', sliceSize=512) {

        const byteCharacters = atob(b64Data);

        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {

          const slice = byteCharacters.slice(offset, offset + sliceSize);

          const byteNumbers = new Array(slice.length);

          for (let i = 0; i < slice.length; i++) {

            byteNumbers[i] = slice.charCodeAt(i);

          }

          const byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);

        }

        const blob = new Blob(byteArrays, {type: contentType});

        return blob;

    }
}
